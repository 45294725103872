/* ------------------------------------------------------------ LATO --------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');


/* ------------------------------------------------------------ RUBIK --------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

/* font-family: 'Lato', sans-serif;
font-family: 'Rubik', sans-serif; */


/* ------------------------------------------------------------ Stylde de base --------------------------------------------------------------- */


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background: #0e1b31;
}

html{
  scroll-behavior: smooth;
}

h1,h2,h3,h4,h5,h6{
  color: #fff;
  margin: 0;
}

h1,h2{
  font-size: 2rem;
  font-weight: 500;
}

p{
  color: #fff;
  opacity: 75%;
  font-size: 1rem;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

ul{
  list-style: none;
  margin: 0;
}

a{
  color: unset;
  font-family: "Lato", sans-serif;
}

a:hover{
  color: unset;
}

section, footer {
  padding: 60px 0px;
}

/* ------------------------------------------------------------ Stylde de base --------------------------------------------------------------- */

::-webkit-scrollbar{
  width: 10px;
  background: #c1ceff;
}


::-webkit-scrollbar-thumb{
border-radius: 2em;
background: #282f4e;
}

@media only screen and (max-width: 992px) {

    .section {
      padding: 40px 0px;
    }
}