

.footer {
    border-top: 1px solid #b80c09bb;
    padding-top: 20px;
}

.footer p {
    font-weight: 300;
    font-size: 0.9rem;
}

@media only screen and (max-width: 992px) {

    .footer p {
     font-size: 0.7rem;
    }
}