@import url(https://fonts.googleapis.com/css?family=Raleway);

#left {
    float: left;
}

#right {
    float: right;
}


.skills-bar-container {
    position: relative;
    width: 40%;
    list-style: none;
    background: #132347;
    padding: 20px;
    border-radius: 10px;
}

.skills-bar-container li {
    background: #666666;
    position: relative;
    width: 100%;
    height: 3px;
    margin-bottom: 15px;
    margin-top: 8px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.skills-bar-container label {
    color: #fff;
    position: relative;
    margin-left: 0px;
    font-size: 16px;
    font-weight: 300;
}

.progressbar {
    position: absolute;
    width: 0%;
    height: 100%;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

#progress-htmlcss {
    -webkit-animation-name: progress-htmlcss;
    animation-name: progress-htmlcss;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
}

#progress-jsjq {
    -webkit-animation-name: progress-jsjq;
    animation-name: progress-jsjq;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}

#progress-wordpress {
    -webkit-animation-name: progress-wordpress;
    animation-name: progress-wordpress;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}

#progress-bootstrap {
    -webkit-animation-name: progress-bootstrap;
    animation-name: progress-bootstrap;
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
}


#progress-php {
    -webkit-animation-name: progress-php;
    animation-name: progress-php;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

#progress-sql {
    -webkit-animation-name: progress-sql;
    animation-name: progress-sql;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}

#progress-symfony {
    -webkit-animation-name: progress-symfony;
    animation-name: progress-symfony;
    -webkit-animation-delay: .7s;
    animation-delay: .7s;
}
#progress-git {
    -webkit-animation-name: progress-sql;
    animation-name: progress-sql;
    -webkit-animation-delay: .8s;
    animation-delay: .8s;
}
.progressblue {
    background-color: var(--element);
}

@-webkit-keyframes progress-htmlcss {
    0% {
        width: 0%;
    }

    100% {
        width: 60%;
    }
}

@-webkit-keyframes progress-jsjq {
    0% {
        width: 0%;
    }

    100% {
        width: 40%;
    }
}

@-webkit-keyframes progress-wordpress {
    0% {
        width: 0%;
    }

    100% {
        width: 65%;
    }
}

@-webkit-keyframes progress-bootstrap {
    0% {
        width: 0%;
    }

    100% {
        width: 65%;
    }
}


@-webkit-keyframes progress-php {
    0% {
        width: 0%;
    }

    100% {
        width: 35%;
    }
}

@-webkit-keyframes progress-sql {
    0% {
        width: 0%;
    }

    100% {
        width: 20%;
    }
}

@-webkit-keyframes progress-symfony {
    0% {
        width: 0%;
    }

    100% {
        width: 55%;
    }
}

@-webkit-keyframes progress-git {
    0% {
        width: 0%;
    }

    100% {
        width: 50%;
    }
}





@-moz-keyframes progress-htmlcss {
    0% {
        width: 0%;
    }

    100% {
        width: 60%;
    }
}

@-moz-keyframes progress-jsjq {
    0% {
        width: 0%;
    }

    100% {
        width: 40%;
    }
}

@-moz-keyframes progress-wordpress {
    0% {
        width: 0%;
    }

    100% {
        width: 65%;
    }
}

@-moz-keyframes progress-bootstrap {
    0% {
        width: 0%;
    }

    100% {
        width: 65%;
    }
}


@-moz-keyframes progress-php {
    0% {
        width: 0%;
    }

    100% {
        width: 35%;
    }
}

@-moz-keyframes progress-sql {
    0% {
        width: 0%;
    }

    100% {
        width: 20%;
    }
}


@-moz-keyframes progress-symfony {
    0% {
        width: 0%;
    }

    100% {
        width: 55%;
    }
}


@-moz-keyframes progress-git {
    0% {
        width: 0%;
    }

    100% {
        width: 50%;
    }
}



@keyframes progress-htmlcss {
    0% {
        width: 0%;
    }

    100% {
        width: 60%;
    }
}

@keyframes progress-jsjq {
    0% {
        width: 0%;
    }

    100% {
        width: 40%;
    }
}

@keyframes progress-wordpress {
    0% {
        width: 0%;
    }

    100% {
        width: 65%;
    }
}

@keyframes progress-bootstrap {
    0% {
        width: 0%;
    }

    100% {
        width: 65%;
    }
}



@keyframes progress-php {
    0% {
        width: 0%;
    }

    100% {
        width: 35%;
    }
}

@keyframes progress-sql {
    0% {
        width: 0%;
    }

    100% {
        width: 20%;
    }
}


@keyframes progress-symfony {
    0% {
        width: 0%;
    }

    100% {
        width: 55%;
    }
}

@keyframes progress-git {
    0% {
        width: 0%;
    }

    100% {
        width: 50%;
    }
}