/* ---------------  Media Queries (Medium services )--------------- */

@media screen and (min-width: 600px) and (max-width: 800px) {

    .contact_container {
        width: 75% !important;
        grid-template-columns: 50% 50% !important;
        gap: 5% !important;
    }

    .contact_option {
        width: 100% !important;
    }

}

@media screen and (min-width: 600px) and (max-width: 1055px) {

    .contact_container {
        width: 75% !important;
        grid-template-columns: 50% 50% !important;
        gap: 5% !important;
    }

    .contact_option {
        width: 90%;
    }

}


@media screen and (min-width: 1055px) and (max-width: 1400px) {

    .contact_container {
        width: 65% !important;
        grid-template-columns: 50% 50% !important;
        gap: 5% !important;
    }


    .contact_option {
        width: 70%;
    }

    .contact_options {

        margin-right: 20px;

    }

    .mail-h5 {
        font-size: 1rem;
    }

}

@media screen and (min-width: 1400px) and (max-width: 1600px) {

    .contact_container {
        width: 55% !important;
        grid-template-columns: 50% 50% !important;
        gap: 5% !important;
    }


    .contact_option {
        width: 70%;
    }

    .contact_options {

        margin-right: 20px;

    }

    .mail-h5 {
        font-size: 1rem;
    }

}


@media screen and (min-width: 1600px) and (max-width: 1900px) {

    .contact_container {
        width: 45% !important;
        grid-template-columns: 60% 60% !important;
        gap: 1% !important;
    }


    .contact_option {
        width: 60%;
    }

    .contact_options {

        margin-right: 20px;

    }

    .mail-h5 {
        font-size: 1rem;
    }

}