
@media screen and (max-width: 767px) {

    .serviceContainer {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: initial;
    }


    .serviceIMage {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .services_box {
        min-width: 170px;
        min-height: 100px !important;
        width: 80px !important;
        height: 275px !important;
        margin: 20px  auto !important;
      }

    .services_box h2 {
        font-size: 1.6rem;
    }

    .services_box p {
        font-size: 1.3rem;
    }



}