

.single_experience-container {
    padding: 30px;
    background: #132347;
    border-radius: 10px;
}


.single_experience {
    position: relative;
    padding: 10px 55px;
    z-index: 79;
}

.single_experience::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    content: '';
    background: var(--element);
    color: var(--element);
    z-index: 80;
}

.single_experience h6 {
    font-size: 1rem;
    opacity: 75%;
    font-weight: 300;
    margin-bottom: 20px;
}

.single_experience h5 {
    font-size: 1.1rem;
    margin-bottom: 15px;
}

.single_experience p {
    font-weight: 300;
    line-height: 30px;
    font-size: 1em;
}

.experience_icon {
    position: absolute;
    top: 3px;
    left: 15px;
    color: var(--element);
    font-size: 1.5rem;
    z-index: 80;
}

.ul_exp {
    padding: 0px;
    margin-bottom: 54px;
}
.ul_exp li {
    color: var(--white);
    list-style: inside;
}

