

@media screen and (max-width:600px) {
    section {
        padding: 0px 0px;
    }

    .about_img {
        width: 80% !important;
        height: 9% !important;
        margin: 0px auto;
        padding: 10px;
        background: none;
    }

    .about_img img {
        border-radius: 0px;
        width: 60% !important;
        margin: 0px auto;
    }

    .about_content_wrapper {
        display: block !important;
        padding: 20px;
    }

    .ab-cont {
        width: 90% !important;
    }

    .about_content h2 {
        margin-top: 50px;
        margin-bottom: 15px;
    }

}


@media screen and (max-width:450px) {
    section {
        padding: 0px 0px;
    }

    .about_img {
        width: 100% !important;
        height: 18.5% !important;
        padding: 10px;
        background: linear-gradient(45deg, #7e0907, var(--element), #7e0907);
    }

    .social_link span a i {
        color: var(--white);
        font-size: 2rem;
        margin-left: 25px;
    }

    .social_link span a i:hover {
        color: rgb(255, 0, 0);
    }

}


