@media screen and (max-width: 992px) {

    .single_experience h6 {
        font-size: 0.7rem;
    }


    .single_experience h5 {
        font-size: 0.9rem;
        margin-bottom: 10px;
    }

    .single_experience p {
        font-size: 0.7rem;
        line-height: 28px;
    }

    .single_experience-container {
        padding: 10px !important;
        margin-bottom: 0px;
    }


    .exp-t2 {
        height: 100%;
    }

    .exp-t2 .single_experience {
        padding-top: 50px;
    }

    .exp-t2 .experience_icon {
        position: absolute;
        top: 43px !important;
        left: 15px;
        color: var(--element);
        font-size: 1.5rem;
        z-index: 80;
    }
}

@media screen and (max-width: 1226px) {


    .exp-t2 {
        height: 100%;
    }

    .exp-t2 .single_experience {
        padding-top: 40px;
    }

    .exp-t2 .experience_icon {
        position: absolute;
        top: 43px !important;
        left: 15px;
        color: var(--element);
        font-size: 1.5rem;
        z-index: 80;
    }
}


