

.header {
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
}

.logo h5 {
    font-size: 1.6rem;
    color: var(--element);
  
}

.nav_list {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
}

.nav_item a {
    color: var(--white);
    transition: .3s;
    text-decoration: none;
    font-weight: 400;
    font-size: 1.3rem;
    cursor: pointer;
}


.nav_item a:hover {
    color: var(--element)
}

.btn {
    background: var(--element);
    color: var(--white);
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: .9rem;
    padding: 7px 25px;
}

.btn.hover {
    color: var(--white);
}

.mobile_menu i {
    color: var(--white);
    font-size: 1.3rem;
}

.mobile_menu{
    display: none;
}

 .header_shrink {
    background: #132347;
    box-shadow: 2px 2px 4px -2px #d41512;
} 


/* ------------------------------------------------------------ RESPONSIVE --------------------------------------------------------------- */

@media only screen and (max-width: 992px) {

.nav_menu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #7765ff69;
    z-index: 888888;
    display: none;
}

.nav_list {
    position: absolute;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #132347;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}


.menu_active {
    display: block;
}

.mobile_menu{
    display: block;
}

/* .btn{
 display: none;
} */

.header{
    height: 50px;
    line-height: 50px;
}



}



@media only screen and (max-width: 340px) {
    .justify-content-between {
        justify-content: space-around !important;
      }

    }