
.contact_container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact_options {
display: flex;
flex-direction: column;
gap: 2rem;
}

h5 a {
    text-decoration: none;
}
.contact_option {
    background: var(--element);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}


.contact_option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--white);
}

.contact_option-icon a, .btn-contact{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    text-decoration: none;
    color: black;
    background-color: var(--white);
    box-shadow: 2px 2px 2px 1px black;
}

.btn-send:hover {
 background-color: var(--white);
}
/* ------------------------------------------------------------ FORM --------------------------------------------------------------- */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--white);
    resize: none;
    color: var(--white);
}



