

.single_award {
    background: #132347;
    padding: 20px;
    border-radius: 5px;
    border-bottom: 1px solid var(--element);
    cursor: pointer;
    transition: 0.3s;
}

.single_award:hover {
    transform: translateY(-15px);
}

.award_item{
    width: 47%;
    margin-bottom: 20px;
}

.award_title span {
    font-weight: 300 !important;
    color: var(--white);
    opacity: 70% !important;
    font-size: 1rem;
}

.award_year  {
    color: var(--white);
    margin-bottom: 15px;
    font-size: 1rem;
}

.diploma {
    color: var(--element);
   font-size: 2.5rem;
   margin-right: 10px;
   
}



@media only screen and (max-width: 992px) {


.award_title {
    font-size: 0.8rem;
}

.award_title span {
    font-size: 0.7rem;
}

.award_year {
    font-size: 0.7rem;
}

}