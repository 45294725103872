@media only screen and (max-width: 780px) {

    .single_experience {
        position: relative;
        padding: 10px 5px 10px 45px !important;
        z-index: 79;
    }

    .single_experience-container {
        padding: 10px !important;
        margin-bottom: 40px !important;
    }

    
}