/* ---------------  Media Queries (Small services) --------------- */

@media screen and (max-width: 800px) {

    .about_card {
        width: 100%;
        height: 85%;
        padding: 1rem;
    }

    .about_icon {
        margin-bottom: 0.5rem;
        font-size: 1.3rem;
    }

    .about_card small {
        font-size: 0.9rem;
        margin-top: 50px;
        color: var(--color-light);
    }
    .hire_btn {
        width: 39%;
    }
}

/* ---------------  Media Queries (Small services) --------------- */

@media screen and (max-width: 600px) {

    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        width: 100%;
        padding-right: auto;
        padding-left: auto;
        margin-right: auto;
        margin-left: auto;
    }

    .container {
        width: 100%;
        margin: 0 auto;
    }

    .hero_section {
        padding-top: 100px;
    }

    .social_links {
        margin: auto 5%;
    }

    .about_cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .card-projects {
        position: relative;
        left: 51%;
    }

    .abput_content {
        text-align: center;
    }

    .about_content p {
        margin: 1.5rem 0;
    }



    .hero_img {
        margin: auto;
    }

    .hero_content {
        margin-bottom: 50px;
    }

    .hero_title {
        font-size: 1.5rem;
    }

    .hire_btn {
        width: 39%;
    }
}


@media screen and (max-width:450px) {



    .about_me {
        width: 100%;
        margin: 0 auto 0rem;
    }


    .about_card {
        padding: 1rem;
    }

    .about_cards {
        display: block;
        width: 60%;
        margin: auto;

    }
    .card-projects {
        position: static;
        
    }
    .hero_img {
        margin: auto;
    }

    .hero_content {
        margin-bottom: 50px;
    }

    .hero_title {
        font-size: 1.5rem;
    }

    .hire_btn {
        width: 39%;
    }

}