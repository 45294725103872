
/* ---------------------- RESPONSIVE ---------------------- */

@media only screen and (max-width: 1000px) {

    .hero_img {
        width: 300px;
        height: 300px;
    }


    .hero_content p {
        font-size: 0.8rem;
    }


    .hero_btns button {
        display: block;
        padding: 5px 20px;
        font-size: 0.8rem;

    }

}


/* ---------------  Media Queries (Medium services )--------------- */

@media screen and (max-width: 1024px) {


    .about_container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about_me {
        width: 70%;
        margin: 0rem auto 0rem;
    }

    .about_card {
        margin-top: 20px;
    }

    .hero_content p {
        margin: 1rem 1.5rem;
        font-size: 1.4rem;
    }


    .btn a{
     font-size: 1.35rem;
}

}