/* ------------------------------------------------------------ About 1 --------------------------------------------------------------- */

.hero_section {
    padding-top: 70px
}

.hero_content p {
    line-height: 30px;
    font-weight: 300;
    font-size: 1.2rem;
}


.about_container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about_me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, #7e0907, var(--element), #7e0907);
    display: grid;
    place-items: center;
    transition: 1s;
    cursor: pointer;
}


.about_me:hover {
    transform: scale(1.1);
}


.about_me-image img {
    border-radius: 1.9rem;
    width: 100%;
}


.about_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about_card {
    background: var(--element);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}


.about_card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about_icon {
    color: var(--white);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about_card h5 {
    font-size: 1.2rem;
    margin-bottom: 7px;
}


.about_card small {
    font-size: 1rem;
    margin-top: 50px;
    color: var(--color-light);
}


.about_content p {
    margin: 1rem 0 1.2rem;
}


.social_links {
    background-color: var(--black);
}

.social_links span a i {
    color: var(--element);
    font-size: 3rem;
    margin-left: 25px;
}

.social_links span a i:hover {
    color: var(--white);
}

.social_links span {
    margin-right: 15px;
}

.social_links span a {
    text-decoration: none;

}


.hire_btn {
    background: var(--white);
    color: var(--black);
    text-decoration: none;
    font-weight: 500;
    width: 20%;
    font-size: 1.2rem;
}


.hire_btn:hover {
    color: rgb(153, 153, 153);
}

.btn a {
    text-decoration: none;
    padding: 10px;
    font-size: 1.2rem;
}

.hero_title {
    font-weight: 600;
}

.hero_title span {
    color: var(--element);
}

.hero_img {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: #B80C09;
    margin-left: auto;
}

/* DESKTOP PORTABLE  */


/* ---------------  Media Queries (Medium services )--------------- */

@media screen and (max-width: 1200px) {

    .about_card {
        width: 110%;
        height: 92%;
        padding: 1rem;
    }

    .about_cards {
        gap: 2rem;
    }

    
    .about_card small {
        font-size: 1rem;
        margin-top: 50px;
        color: var(--color-light);
    }
    .hire_btn {
        width: 30%;
    }
}


/* ---------------  Media Queries (Medium services )--------------- */

@media screen and (max-width: 1400px) {

    .about_card {
        width: 100%;
        height: 100%;
        padding: 1rem;
    }

    .about_cards {
        gap: 2rem;
    }

    
    .about_card small {
        font-size: 1rem;
        margin-top: 50px;
        color: var(--color-light);
    }
    .hire_btn {
        width: 30%;
    }
}





