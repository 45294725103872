


.table {
    border: none;
    border-collapse: collapse;
    border: 1px solid #132347;
    border-radius: 5px;
}

th, td {
    padding: 1.5rem 1rem !important;
    font-size: 1rem;
}

thead {
    background: var(--element);
    color: var(--white);
}

td {
    font-size: 0.9rem;
    color: var(--white);
    font-weight: 400;
}

tr:nth-child(even) {
    background: #102048;
}


@media only screen and (max-width: 992px) {

th, td {

font-size: 0.7rem;
padding: 0.8rem;

}








}