.projects {
    margin-top: 180px;
}


.projectsContainer {
    row-gap: 3rem;
}

.h2-port{
    margin-top: 80px;
}

.wrap {
    margin: 50px auto 0 auto;
    width: 100%;
    display: flex;
    align-items: space-around;
    max-width: 1400px;
}

.tile {
    width: 480px;
    height: 300px;
    margin: 10px;
    background-color: var(--element);
    border-radius: 10px;
    display: inline-block;
    background-size: cover;
    position: relative;
    cursor: pointer;
    transition: all 0.4s ease-out;
    box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.44);
    overflow: hidden;
    color: white;
    font-family: 'Roboto';

}

.tile img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: all 0.4s ease-out;
}

.tile .text {
    /*   z-index:99; */
    position: absolute;
    padding: 30px;
    height: calc(100% - 60px);
}

.tile h1 {
    font-size: 2rem;
    font-weight: 300;
    margin: 20px 0 0 0;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    transform: translateX(200px);
}

.tile h2 {
    font-size: 1.7rem;
    font-weight: 100;
    margin: 20px 0 0 0;
    font-style: italic;
    transform: translateX(200px);
}

.tile p {
    font-size: 1.2rem;
    font-weight: 300;
    margin: 20px 0 0 0;
    line-height: 25px;
    /*   opacity:0; */
    transform: translateX(-200px);
    transition-delay: 0.2s;
}

.animate-text {
    opacity: 0;
    transition: all 0.6s ease-in-out;
}

.tile:hover {
    /*   background-color:#99aeff; */
    box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.64);
    transform: scale(1.05);
}

.tile:hover img {
    opacity: 0.2;
}

.tile:hover .animate-text {
    transform: translateX(0);
    opacity: 1;
}


.tile:hover span {
    opacity: 1;
    transform: translateY(0px);
}

