
@media (max-width: 1025px) {

    .animate-text {
        opacity: unset !important;
    }

    .tile h1 {
        position: relative;
        font-size: 2rem;
        font-weight: 300;
        bottom: 35%;
        right: 100%;
    }

    .tile h2 {
        position: relative;
        font-size: 1.7rem;
        bottom: 45%;
        right: 100%;
        font-weight: 100;
        margin: 20px 0 0 0;
    }


    .port-anim h1 {
        position: relative;
        font-size: 2rem;
        font-weight: 300;
        bottom: 35%;
        right: 190%;
    }

    .port-anim h2 {
        position: relative;
        font-size: 1.7rem;
        bottom: 45%;
        right: 190%;
        font-weight: 100;
        margin: 20px 0 0 0;
    }

    .anim1 {
        bottom: 25%;
        right: 10%;
    }

    .anim3 {
        bottom: 25%;
        right: 80%;
    }

    .anim4 {
        bottom: 25%;
        right: 70%;
    }

    .anim5 h1 {
        bottom: 25%;
        right: 110%;
    }


    .tile p {
        display: none;
    }

    .tile img {
        height: 100%;
        width: 10%;
    }

    .tile .text {
        /*   z-index:99; */
        position: static;
        padding: 90px;
        height: calc(100% - 60px);
    }
}