
@media only screen and (max-width: 1024px) {

    .about_btn {
        padding: 10px 25px;
        font-size: 0.8rem;
    }

    .about_btns {
        margin-bottom: 40px;
    }

    .about_img {
        height: 102px;
    }


    .social_link span a i {
        margin-left: 10px;
    }
    
    .social_link span {
        margin-right: 0px;
    }
        /* SKILLS OU COMPETENCES */

        .skills-bar-container {
            position: relative;
            width: 170% !important;
            list-style: none;
            padding: 20px;
            margin-top: 20%;
        }
    
        #left {
            padding-right: 40%;
            width: 100%;
            margin: 10px auto;
        }
    
        #right {
            padding-right: 40%;
            width: 100%;
            margin: 10px auto;
        }
}

@media screen and (min-width: 992px) and (max-width: 1021px) {

    .about_img {
        height: 125px !important;
    }
        /* SKILLS OU COMPETENCES */

        .skills-bar-container {
            width: 150% !important;
        }
    
        #left {
            padding-right: 40%;
            width: 90%;
            margin: 10px auto;
        }
    
        #right {
            padding-right: 40%;
            width: 90%;
            margin: 10px 60px;
        }
}

@media screen and (min-width: 1021px) and (max-width: 1200px) {

    .about_img {
        height: 135px !important;
    }
        /* SKILLS OU COMPETENCES */

        .skills-bar-container {
            width: 150% !important;
        }
    
        #left {
            padding-right: 40%;
            width: 90%;
            margin: 10px auto;
        }
    
        #right {
            padding-right: 40%;
            width: 90%;
            margin: 10px 60px;
        }
}


@media screen and (min-width: 1200px) and (max-width: 1400px) {

    .about_img {
        height: 155px !important;
    }
        /* SKILLS OU COMPETENCES */

        .skills-bar-container {
            width: 150% !important;
            min-width: 300px;
        }
        #left {
            width: 32%;
            margin: 10px auto;
        }
    
        #right {
            width: 35%;
            margin: 10px 60px;
        }
    
}


@media screen and (min-width: 1400px) and (max-width: 1500px) {

    .about_img {
        height: 185px !important;
    }


}

@media screen and (min-width: 1400px) and (max-width: 5600px) {

            /* SKILLS OU COMPETENCES */

            .skills-bar-container {
                width: 145% !important;
                min-width: 200px;
            }
            #left {
                width: 35%;
                margin: 10px auto;
            }
        
            #right {
                width: 35%;
                margin: 10px 60px;
            }
}