@media (max-width: 358px) {

    .anim1{
        right: 5% !important;
    }
}

@media screen and (min-width: 410px) and (max-width: 460px) {

    .anim1{
        right: 30% !important;
    }
}

@media screen and (min-width: 460px) and (max-width: 562px) {

    .anim1{
        right: 45% !important;
    }

    .anim3{
        right: 90% !important;
    }
    
    .anim4{
        right: 80% !important;
    }
}

@media screen and (min-width: 561px) and (max-width: 620px) {
    .wrap {
        flex-direction: column;
        width: 70% !important;
    }

    .anim1{
        right: 25% !important;
    }

    .anim3{
        right: 85% !important;
    }
    
    .anim4{
        right: 72% !important;
    }
}

@media screen and (min-width: 620px) and (max-width: 750px) {
    .wrap {
        flex-direction: column;
        width: 70% !important;
    }

    .anim1{
        right: 65% !important;
    }

    .anim3{
        right: 85% !important;
    }
    
    .anim4{
        right: 72% !important;
    }
}

@media (max-width: 750px) {
    .wrap {
        flex-direction: column;
        width: 400px;
    }

    .tile {
        width: 90% !important;
        height: 250px !important;
        margin: 15px auto !important;
    }
}




@media screen and (min-width: 750px) and (max-width: 850px) {

    .tile {
        width: 90% !important;
        height: 250px !important;
    }

    .anim1{
        right: 40% !important;
    }

 
    .anim4{
        right: 62% !important;
    }
    .anim5{
        right: 45% !important;
    }
    .anim6{
        right: 49% !important;
    }
}

@media screen and (min-width: 850px) and (max-width: 1025px) {

    .tile {
        width: 90% !important;
        height: 250px !important;
    }

    .anim1{
        right: 40% !important;
    }

 
    .anim4{
        right: 62% !important;
    }
    .anim5{
        right: 1% !important;
        width: 90%;
    }
    .anim6{
        right: 55% !important;
    }
}