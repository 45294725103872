.serviceContainer{
    row-gap: 2rem;
    text-align: center;
    margin-top: 100px;
}

.title-service{
    margin-top: 80px;
}


.serviceIMage {
    justify-self: center;
}


.services_box {
    min-width: 270px;
    min-height: 300px;
    width: 380px;
    height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #132347;
    border-bottom: 3px solid var(--element);
    box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.15);
    padding: 10px 15px;
    border-radius: 10px;
    margin: var(--mb-2) 0;
    transition: 1s;
    cursor: pointer;
}


.projectImg img {
    transition: 1s;
    cursor: pointer;
}

.services_box:hover {
    transform: scale(1.1);
}


.services_box h2 {
    color: var(--element);
    font-size: var(--h2-font-size);
}

.services_box p {
    font-size: 15px;
    text-align: center
}



@media screen and (max-width: 3000px) {


    .serviceContainer {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: initial;
    }


    .serviceIMage {
        flex-direction: row;
        display: flex;
        align-items: center;
    }

    .services_box {
        width: 380px;
        height: 380px;
        margin: var(--mb-6);
    }

}
@media screen and (max-width: 1600px) {



    .services_box {
        width: 330px;
        height: 330px;
        margin: var(--mb-6);
    }

}
@media screen and (max-width: 1450px) {



    .services_box {
        width: 310px;
        height: 300px;
        margin: var(--mb-6);
    }

}


@media screen and (max-width: 1350px) {


    .services_box {
        width: 200px;
        height: 200px;
        margin: var(--mb-6);
    }

}


@media screen and (max-width: 1249px) {


    .serviceContainer {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: initial;
    }


    .serviceIMage {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .services_box {
        width: 450px;
        height: 400px;
        margin: var(--mb-6);
    }

}



@media screen and (max-width: 1199px) {




    .services_box {
        width: 400px;
        height: 400px;
        margin: var(--mb-6);
    }



    .services_box h2 {
        font-size: 1.6rem;
    }

    .services_box p {
        font-size: 1.3rem;
    }



}




@media screen and (max-width: 1049px) {


    .serviceContainer {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: initial;
    }


    .serviceIMage {
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .services_box {
        width: 350px;
        height: 350px;
        margin: var(--mb-6);
    }



    .services_box h2 {
        font-size: 1.6rem;
    }

    .services_box p {
        font-size: 1.3rem;
    }



}

