/* ---------------  Media Queries (Small services) --------------- */
@media screen and (max-width: 600px) {


    .contact_container {
        display: block !important;
        width: 55% !important;
    }

    .contact_options {
        display: block !important;
        margin-top: 50px;
    }
    .contact_option {
        margin: 30px 0px;
        padding: 1rem !important;
    }
    .mail-h5 {
        font-size: 1rem;
    }

}

@media screen and (max-width: 400px) {

    .contact_container {
        display: block !important;
        width: 79% !important;
    }

}

@media screen and (max-width: 280px) {
    .contact_container {
        display: block ;
        width: 95% !important;
    }
}