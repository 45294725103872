
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

@import url(https://fonts.googleapis.com/css?family=Marck+Script:regular);

 /* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  margin: var(--header-height) 0 0 0;
}  */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  */




/* V2 */

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;

  --container-widht-lg: 75%;
  --container-widht-md: 86%;
  --container-widht-ms: 90%;

  
    --element: #B80C09;
    --white: #fff;
    --black: #0e1b31;

}

.container {
  width: var(--container-widht-lg);
  margin: 0 auto;
}

section {
  margin-top: 8rem;
}

section > h2, section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  margin-bottom: 3rem;
  color: var(--color-primary);
  font-family: var(--body-font);

}



img{
  display: block;
  width: 50%;
  object-fit: cover;
}


/* ------------------------------------------------------------ MEDIA QUERIES --------------------------------------------------------------- */
@media  screen and (min-width: 1080px) {

.bd_grid {
  margin-left: auto;
  margin-right: auto;
}
  
  }

@media only screen and (min-width: 1024px) {

.container {
  width: var(--container-widht-md);
}

section {
  margin-top: 6rem;
}

}


@media only screen and (min-width: 600px) {

  .container {
    width: var(--container-widhtsm);
  }
  
  section > h2 {
    margin-bottom: 2rem;
  }
  
  }
  

  
/* ------------------------------------------------------------ V3 --------------------------------------------------------------- */
:root {
  --header-height: 3rem;
  --font-semai : 600;
  }
  

/* FONT STYLES  */

:root {
  --header-font : "Poppins" , sans-serif;
  --body-font : "Marck Script", cursive;
  --big-font-size : 2rem;
  --h2-font-size: 1.5rem;
  --normal-font-size : 0.938rem;
}








/* Media screen 768 px min  */
@media only screen and (min-width: 768px) {
  :root {
    --big-font-size : 3.5rem;
    --h2-font-size: 2rem;
    --normal-font-size : 1rem;
  }

  body {
    margin: 0;
  }


  .section {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }

  .section_title {
    margin-bottom: var(--mb-6);
  }

  .section_title::after {
   width: 80px;
   top: 3rem;
  }

}

/* MARGINS  */
:root {
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;
}

/* Z-INDEX */
:root {
  --z-back : -10;
  --z-normal : 1;
  --z-tooltip : 10;
  --z-fixed :100;
}


.bd_grid {
  max-width: 1524px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}


.section_title {
  position: relative;
  font-size: 3rem;
  color: var(--element);
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-4);
  text-align: center;
  font-family: var(--body-font);
}

.section_title::after {
  position: absolute;
  content: " ";
  width: 84px;
  height: 0.18rem;
  left: 0;
  right: 0;
  margin:auto;
  top: 3.7rem;
  background-color: #fff;
}

.section {
  padding-top: 3rem;
  padding-bottom: 2rem;
  position: relative;
}

