

/* ------------------------------------------------------------ About 2 --------------------------------------------------------------- */

.about_btns {
    background: #102048;
    border-radius: 10px;
    padding: 10px;
}

.about_btn {
    border: none;
    outline: none;
    width: 105%;
    padding: 20px;
    color: var(--white);
    background: transparent;
    border-bottom: 1px solid var(--element);
    border-radius: 5px 5px 0px 0px;
}

.about_btn-active {
    background: var(--element);
}

.about_btn:last-child {
    border-bottom: 0;
    border-radius: 0px 0px 5px 5px;
}

.about_content_wrapper {
    background: #132347;
    padding: 30px;
    border-radius: 10px;
}

.about_content h2 {
    margin-bottom: 15px;
}

.about_content p {
    line-height: 30px;
    font-weight: 300;
}


.about_img {
    background: var(--element);
    border-radius: 10px;
    height: 186px;
}

.about_img img {
    border-radius: 10px;
}

.social_link span a i {
    color: var(--element);
    font-size: 2rem;
    margin-left: 25px;
}

.social_link span a i:hover {
    color: var(--white);
}

.social_link span {
    margin-right: 15px;
}

.social_link span a {
   text-decoration: none;
   
}


